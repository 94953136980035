import { FeaturedQuery } from '@generated/GraphqlTypes';
import FeaturedDetailed from '@localTypes/FeaturedDetailed';
import slug from '@helpers/slug';

const decodeFeatured = (data: FeaturedQuery): FeaturedDetailed => {
  const title = data.markdownRemark?.frontmatter?.title || '';
  const traditionalName =
    data.markdownRemark?.frontmatter?.traditionalName || '';
  const hero = data.markdownRemark?.frontmatter?.hero?.publicURL || undefined;
  const images = (data.markdownRemark?.frontmatter?.images || []).map(
    a => (a as any).publicURL
  ) as string[];
  const video = data.markdownRemark?.frontmatter?.video || '';
  const price = data.markdownRemark?.frontmatter?.price ?? undefined;

  const body = data.markdownRemark?.html || '';
  const graphFeatures = (data.markdownRemark?.frontmatter?.features ||
    {}) as Record<string, string>;
  const featuresKeys = Object.keys(graphFeatures);
  const features = new Map(
    featuresKeys.map((a: string) => [a, graphFeatures[a] || ''])
  );

  return {
    title,
    traditionalName,
    slug: slug(title),
    hero,
    video,
    price,
    images,
    body,
    features,
  };
};

export default decodeFeatured;
